<template>
  <div class="container-fluid">
    <div class="row mb-5" v-if="showHeader">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.DETAILS") }}</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openSalesInvoiceItemModal()"
          type="button"
          class="btn btn-sm btn-primary"
          v-if="salesInvoice.status === INVOICE_STATUS_DRAFT"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          {{ $t("SALES_INVOICES.ADD_SALES_INVOICE_ITEM") }}
        </base-button>
      </div>
    </div>

    <sales-invoice-view-details-sales-invoice-item-table
      :salesInvoice="salesInvoice"
      @onOpenSalesInvoiceItemModal="openSalesInvoiceItemModal"
    />

    <div class="row mb-5" v-if="salesInvoice.pricing">
      <div class="col-6"></div>
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.SUBTOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesInvoice.pricing.subtotal) }}
          </dd>
        </dl>

        <dl class="row mb-0" v-if="salesInvoice.pricing.discounts.total">
          <dt class="col-sm-6">{{ $t("COMMON.DISCOUNTS") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesInvoice.pricing.discounts.total * -1) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(discount, index) in salesInvoice.pricing.discounts
                .details"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ discount.name }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(discount.amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0" v-if="salesInvoice.pricing.taxes.total">
          <dt class="col-sm-6">{{ $t("COMMON.TAXES") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesInvoice.pricing.taxes.total) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(tax, index) in salesInvoice.pricing.taxes.taxes"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ tax.label }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(tax.tax_amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesInvoice.pricing.total) }}
          </dd>
        </dl>
      </div>
    </div>

    <!-- Modal add slot -->
    <sales-invoice-view-details-sales-invoice-item-form
      :salesInvoice="salesInvoice"
      :salesInvoiceItem="salesInvoiceItem"
      :showModal="showModal"
      :formErrors="itemsFormError"
      @onCloseSalesInvoiceItemModal="closeSalesInvoiceItemModal"
      @onSubmitSalesInvoiceItemForm="handleSalesInvoiceItemFormSubmit"
      @onDeleteSalesInvoiceItem="deleteSalesInvoiceItem"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
} from "@/constants/invoices";
import SalesInvoiceViewDetailsSalesInvoiceItemForm from "./SalesInvoiceViewDetailsSalesInvoiceItemForm.vue";
import SalesInvoiceViewDetailsSalesInvoiceItemTable from "./SalesInvoiceViewDetailsSalesInvoiceItemTable.vue";
import defaultSalesInvoiceItem from "../defaultSalesInvoiceItem";

export default {
  name: "sales-invoice-view-details",

  components: {
    SalesInvoiceViewDetailsSalesInvoiceItemForm,
    SalesInvoiceViewDetailsSalesInvoiceItemTable,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    showHeader: {
      default: true,
    },
    salesInvoice: {
      default: {},
    },
  },

  data() {
    return {
      salesInvoiceItem: cloneDeep(defaultSalesInvoiceItem),
      loading: false,
      showModal: false,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      itemsFormError: null,
    };
  },

  computed: {},

  methods: {
    openSalesInvoiceItemModal(salesInvoiceItem = null) {
      if (!salesInvoiceItem) {
        this.salesInvoiceItem = cloneDeep(defaultSalesInvoiceItem);
      } else {
        this.salesInvoiceItem = cloneDeep(salesInvoiceItem);
      }
      this.showModal = true;
    },

    closeSalesInvoiceItemModal() {
      this.showModal = false;
      this.loading = false;
    },

    handleSalesInvoiceItemFormSubmit(salesInvoiceItemData) {
      if (!salesInvoiceItemData.id) {
        this.addSalesInvoiceItem(salesInvoiceItemData);
      } else {
        this.editSalesInvoiceItem(salesInvoiceItemData);
      }
    },

    async addSalesInvoiceItem(salesInvoiceItemData) {
      this.loading = true;
      try {
        salesInvoiceItemData.salesInvoice.id = this.salesInvoice.id;

        await this.$store.dispatch(
          "salesInvoiceItems/add",
          salesInvoiceItemData
        );
        this.$emit("salesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_INVOICES.SALES_INVOICE_ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async editSalesInvoiceItem(salesInvoiceItemData) {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "salesInvoiceItems/update",
          salesInvoiceItemData
        );
        this.$emit("salesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_INVOICES.SALES_INVOICE_ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async deleteSalesInvoiceItem(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("salesInvoiceItems/destroy", id);
        this.$emit("salesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_INVOICES.SALES_INVOICE_ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
