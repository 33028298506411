<template>
  <div class="">
    <div class="row">
      <div class="col-12 col-md-5">
        <documents-send-mail-form
          :documentData="salesInvoice"
          :dispatchUrl="'salesInvoices/sendPaymentMail'"
          :toggleMailModal="toggleMailModal"
          :mailInformation="mailInformation"
        />
      </div>
      <div
        class="col-12 col-md-7"
        style="max-height: 550px; overflow-y: scroll"
      >
        <sales-payment-list-table
          :showHeader="false"
          v-if="salesInvoice?.id"
          :filterInvoice="salesInvoice.id"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <el-button type="danger" @click="toggleMailModal">
        {{ $t("COMMON.CANCEL") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import "flatpickr/dist/flatpickr.css";
import formMixin from "@/mixins/form-mixin";
import DocumentsSendMailForm from "@/components/DocumentsSendMailForm";
import SalesPaymentListTable from "../../SalesPaymentManagement/partials/SalesPaymentListTable.vue";

export default {
  layout: "DashboardLayout",
  name: "sales-order-mail-modal-content",

  components: {
    DocumentsSendMailForm,
    [Button.name]: Button,
    SalesPaymentListTable,
  },

  mixins: [formMixin],
  props: {
    salesInvoice: {
      default: () => {},
    },
    toggleMailModal: {
      type: Function,
    },
    get: {
      type: Function,
    },
    loading: {
      default: () => true,
    },
  },

  data() {
    return {
      submitting: false,
      mailInformation: {
        organization: {},
        recipient: {},
        user: {},
      },
    };
  },

  created() {},

  mounted() {
    this.mailInformation = {
      organization: this.salesInvoice.organization,
      subject: this.$t("COMMON.EMAIL_INFOS_SUBJECT_INVOICE", {
        organization: this.salesInvoice.organization?.name,
        code: this.salesInvoice.code,
      }),
      recipient: {
        username: this.salesInvoice?.recipient?.customer_name,
        email: this.salesInvoice?.recipient?.email,
      },
    };
  },

  methods: {},

  watch: {
    salesInvoice(salesInvoice) {
      this.mailInformation = {
        organization: salesInvoice.organization,
        subject: this.$t("COMMON.EMAIL_INFOS_SUBJECT_PAYMENT", {
          organization: salesInvoice.organization?.name,
          code: salesInvoice.code,
        }),
        recipient: {
          username: salesInvoice?.recipient?.customer_name,
          email: salesInvoice?.recipient?.email,
        },
      };
    },
  },
};
</script>
